import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { ProductInformation } from '@orus.eu/dimensions'
import {
  Avatar,
  AvatarDecorative,
  Button,
  ButtonLink,
  Text,
  Tooltip,
  colorTokens,
  secondaryColor,
  shadow,
  spacing,
  useLanguage,
  useScreenVariant,
  useTranslate,
  type ButtonProps,
} from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import {
  productAttributeValueToString,
  projectAttributeInProductBlock,
  useProductAttributesProjection,
  type ProjectedProductAttributeDescription,
} from '../../../../hooks/use-product-attributes-projection.js'
import { productsAvatarConfigurations } from './productAvatarConfiguration.js'

export type ProductCard = {
  productInformation: ProductInformation
  price: string | undefined
  onLearnMoreClick?: () => void
  onShowGuaranteesClick?: () => void
  showGuaranteesInNewPage?: boolean
  hasLearnMoreButton?: boolean
  hasShowGuaranteesButton?: boolean
  size?: 'small' | 'large'
  children?: ReactNode
}

export const ProductCard = memo<ProductCard>(function ProductCard(props) {
  const translate = useTranslate()
  const {
    productInformation,
    price,
    onLearnMoreClick,
    onShowGuaranteesClick,
    children,
    showGuaranteesInNewPage,
    hasLearnMoreButton,
    hasShowGuaranteesButton,
  } = props

  const screenVariant = useScreenVariant()

  return (
    <ProductCardContainer>
      <ProductQuoteHeader productInformation={productInformation} price={price} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${screenVariant === 'desktop' ? spacing[50] : spacing[70]};
        `}
      >
        <ProductAttributesList productInformation={productInformation} />
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: ${showGuaranteesInNewPage ? spacing[50] : spacing[30]};
            justify-content: flex-end;
          `}
        >
          {hasLearnMoreButton ? (
            <Button
              variant={showGuaranteesInNewPage ? 'text' : 'secondary'}
              size={showGuaranteesInNewPage ? 'medium' : 'small'}
              onClick={onLearnMoreClick}
            >
              {translate('subscription_funnel_product_indicator_learn_more')}
            </Button>
          ) : null}

          {hasShowGuaranteesButton ? (
            showGuaranteesInNewPage ? (
              <OpenGuaranteesButton
                productInformation={productInformation}
                size={screenVariant === 'desktop' ? 'medium' : 'large'}
              />
            ) : (
              <Button variant="secondary" size="small" onClick={onShowGuaranteesClick}>
                {translate('see_guarantees')}
              </Button>
            )
          ) : null}
        </div>
      </div>
      {children}
    </ProductCardContainer>
  )
})

const ProductCardContainer = styled.div`
  display: flex;
  padding: ${spacing[60]};
  flex-direction: column;

  gap: ${spacing[60]};
  align-self: stretch;

  border-radius: ${spacing[40]};
  border: 1px solid ${colorTokens['color-stroke-base']};
  background: ${colorTokens['color-bg-base-normal']};
  box-shadow: ${shadow.bottom[20]};
`

type ProductQuoteHeaderProps = {
  productInformation: ProductInformation
  price: string | undefined
}
export const ProductQuoteHeader = memo<ProductQuoteHeaderProps>(function ProductQuoteHeader({
  productInformation,
  price,
}) {
  const screenVariant = useScreenVariant()

  return (
    <div
      css={
        screenVariant === 'desktop'
          ? css`
              display: flex;
              gap: ${spacing[50]};
              align-items: center;
            `
          : css`
              display: flex;
              gap: ${spacing[50]};
              flex-direction: column;
            `
      }
    >
      <div
        css={css`
          display: flex;
          gap: ${spacing[50]};
          align-items: center;
          justify-content: space-between;
          flex: 1 1;
          flex-wrap: wrap;
        `}
      >
        <AvatarDecorative size="60" {...productsAvatarConfigurations[productInformation.product]} />
        <Text
          variant="subtitle2"
          css={css`
            flex: 1 1;
          `}
        >
          {productInformation.name}
        </Text>
        {screenVariant !== 'desktop' && price ? <Text variant="body2Medium">{price}</Text> : <></>}
      </div>
      {screenVariant === 'desktop' && price ? <Text variant="body2Medium">{price}</Text> : <></>}
    </div>
  )
})

type ProductAttributesListProps = { productInformation: ProductInformation }
export const ProductAttributesList = memo<ProductAttributesListProps>(function ProductAttributesList({
  productInformation,
}) {
  const productAttributes = useProductAttributesProjection(
    productInformation.attributes,
    projectAttributeInProductBlock,
  )

  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[30]};
        flex-direction: column;
      `}
    >
      {productAttributes.map((productAttribute) => (
        <div
          key={productAttribute.label}
          css={css`
            display: flex;
            gap: ${spacing[50]};
          `}
        >
          <Text
            variant="body2"
            css={css`
              flex: 1;
              text-align: left;
            `}
          >
            {productAttribute.label}
          </Text>
          <ProductAttributeValueText productAttribute={productAttribute} />
        </div>
      ))}
    </div>
  )
})

type ProductAttributeValueColumnProps = { productAttribute: ProjectedProductAttributeDescription }
export const ProductAttributeValueText = memo<ProductAttributeValueColumnProps>(
  function ProductAttributeValueText(props) {
    const language = useLanguage()
    const { productAttribute } = props

    const isGood = productAttribute.type === 'included' && productAttribute.value
    const text = productAttributeValueToString(productAttribute, language)

    return (
      <>
        <Text
          css={css`
            text-align: right;
            flex: 1;
          `}
          variant="body2Medium"
          color={isGood ? secondaryColor : undefined}
        >
          {text}
        </Text>
        {isGood ? (
          <Avatar icon="check-regular" size="30" color={secondaryColor} />
        ) : productAttribute.tooltip ? (
          <Tooltip title={productAttribute.tooltip}>
            <Avatar icon="circle-info-regular" size="30" />
          </Tooltip>
        ) : (
          <></>
        )}
      </>
    )
  },
)

type OpenGuaranteesButtonProps = Omit<ButtonProps, 'href' | 'onClick' | 'children'> & {
  productInformation: ProductInformation
}
export const OpenGuaranteesButton = memo<OpenGuaranteesButtonProps>(function OpenGuaranteesButton({
  productInformation,
  ...passedProps
}) {
  const translate = useTranslate()
  const productName = productInformation.name
  return (
    <>
      <ButtonLink
        ariaLabel={translate('see_product_guarantees', { productName })}
        variant="tertiary"
        from="/subscribe/$subscriptionId/$stepId"
        to="/subscribe/$subscriptionId/$stepId/$detailType/$detailId"
        params={{ detailType: 'guarantees', detailId: productName }}
        {...passedProps}
      >
        {translate('see_guarantees')}
      </ButtonLink>
    </>
  )
})
