import type { ProductInformationId } from '@orus.eu/dimensions'
import type { AvatarDecorativeProps } from '../../../../index.js'

export const productsAvatarConfigurations: Record<
  ProductInformationId,
  Required<Pick<AvatarDecorativeProps, 'icon' | 'backgroundColor'>>
> = {
  mrph: { icon: 'warehouse-light', backgroundColor: 'jasmine' },
  mrpw: { icon: 'shop-light', backgroundColor: 'jasmine' },
  restaurant: { icon: 'user-chef-light', backgroundColor: 'jasmine' },
  'rcph-pj': { icon: 'scale-balanced-light', backgroundColor: 'peach' },
  'rcph-cyber': { icon: 'shield-halved-light', backgroundColor: 'mindaro' },
  'rcph-tdmi': { icon: 'laptop-mobile-light', backgroundColor: 'periwinkle' },
  'rc-pro': { icon: 'book-open-light', backgroundColor: 'sky' },
  muta: { icon: 'stethoscope-light', backgroundColor: 'peach' },
  rcda: { icon: 'shovel-light', backgroundColor: 'periwinkle' },
  'rcda-rc-pro': { icon: 'book-open-light', backgroundColor: 'sky' },
  'es-rcph': { icon: 'book-open-light', backgroundColor: 'sky' },
}
